import React from "react";
import { Box, VStack, Center, Stack, Text, Divider, Grid, Image, Tooltip, useBreakpointValue, Link } from "@chakra-ui/react";
import { casesItems } from "../lists/CasesItems";

const Cases = () => {
  const gridTemplateColumns = useBreakpointValue({ base: "repeat(1, 1fr)", sm: "repeat(2, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" });
  const isMobile = useBreakpointValue({ base: true, sm: false });

  return (
    <VStack spacing={10}>

      {/* Cases Section */}
      <Box w="100%" pt='120px' pb='20px'>
        <Center>
          <Stack spacing={4} maxWidth="1200px">
            <Box position="relative">
              <Text fontSize="2xl" fontWeight="bold" align="center">Cases</Text>
            </Box>
            <Divider orientation="horizontal" />
          </Stack>
        </Center>
      </Box>

      {/* Cases Images Section */}
      <Grid templateColumns={gridTemplateColumns} gap={6}>
        {casesItems.map((item, index) => (
          <Box key={index}>
            {isMobile && <Text textAlign="center">{item.title}</Text>}
            <Tooltip label={item.title}>
              <Link href={item.caseUrl} isExternal>
                <Box _hover={{ opacity: 0.5 }} transition="opacity 0.3s">
                  <Image src={item.imageUrl} alt={item.title} boxSize="200px" objectFit="cover" />
                </Box>
              </Link>
            </Tooltip>
          </Box>
        ))}
      </Grid>

    </VStack>
  )
};

export default Cases;
