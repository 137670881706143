import {
  Box,
  Flex,
  Link,
  Image,
  IconButton,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerBody,
  UnorderedList,
  ListItem,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const menuItems = [
    { href: "/about", text: "The Studio" },
    { href: "/portfolio", text: "Portfolio" },
    //{ href: "/cases", text: "Cases" },
    { href: "/contact", text: "Contact" },
    { href: "https://clientstudio.firegrader.com/", text: "Client Studio" },
  ];

  const logoUrl =
    "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/MainLogo/Logo_Firegrader_BrancoFinal.png";

  const bgColor = "gray.800";
  const color = "white";
  const iconColor = "white";

  return (
    <Box
      as="header"
      bgColor={bgColor}
      color={color}
      w="full"
      position="fixed"
      top="0"
      zIndex="docked"
    >
      <Flex justify="space-between" align="center" p={8}>
        {/* Logo - Visible on all screen sizes */}
        <Link href="/" display={{ base: "block", md: "block" }}>
          <Image src={logoUrl} alt="Firegrader" />
        </Link>

        {/* Desktop Navigation */}
        <Flex display={{ base: "none", md: "flex" }}>
          {menuItems.map((item, index) => (
            <Box
              as="a"
              href={item.href}
              key={index}
              mx={4}
              position="relative"
              _after={{
                content: '""',
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "0%",
                height: "2px",
                backgroundColor: color,
                transition: "width 0.2s ease-in-out",
              }}
              _hover={{
                _after: {
                  width: "100%",
                },
              }}
            >
              {item.text}
            </Box>
          ))}
        </Flex>

        {/* Mobile Menu Button */}
        <IconButton
          display={{ base: "flex", md: "none" }}
          aria-label="Open menu"
          variant="outline"
          icon={<HamburgerIcon boxSize={6} color={iconColor} />}
          onClick={onOpen}
          ml="auto"
        />
      </Flex>

      {/* Mobile Navigation Drawer */}
      <Drawer isOpen={isOpen} onClose={onClose} placement="right">
        <DrawerOverlay>
          <DrawerContent bgColor={bgColor} color={color}>
            <DrawerCloseButton />
            <DrawerBody paddingTop={4}>
              <UnorderedList styleType="none" spacing={4}>
                {menuItems.map((item, index) => (
                  <ListItem key={index}>
                    <Box
                      as="a"
                      href={item.href}
                      position="relative"
                      _after={{
                        content: '""',
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "0%",
                        height: "2px",
                        backgroundColor: color,
                        transition: "width 0.2s ease-in-out",
                      }}
                      _hover={{
                        _after: {
                          width: "100%",
                        },
                      }}
                    >
                      {item.text}
                    </Box>
                  </ListItem>
                ))}
              </UnorderedList>
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </Box>
  );
};

export default Header;
