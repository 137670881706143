import React, { useRef, useState } from "react";
import {
  Box,
  VStack,
  FormControl,
  Input,
  Textarea,
  Stack,
  Text,
  Divider,
  Center,
  Button,
  useBreakpointValue,
} from "@chakra-ui/react";
import emailjs from "@emailjs/browser";

const Contact = () => {
  const direction = useBreakpointValue({ base: "column", md: "row" });

  const form = useRef();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageSent, setMessageSent] = useState(false);

  function sendEmail(e) {
    e.preventDefault();

    if (email === "" || name === "" || message === "") {
      alert("Please, fill in all fields.");
    }

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        form.current,
        {
          publicKey: process.env.REACT_APP_EMAILJS_PUBLIC_KEY,
        }
      )
      .then(() => {
        setMessageSent(true);
        setName("");
        setEmail("");
        setMessage("");
      });
  }

  return (
    <VStack spacing={10}>
      {/* Contact Section */}
      <Box w="100%" pt="160px" pb="20px" color="white">
        <Center>
          <Stack spacing={4} maxWidth="1200px">
            <Box position="relative">
              <Text fontSize="2xl" fontWeight="bold" align="center">
                Contact
              </Text>
            </Box>
            <Stack direction={direction} spacing={2} align="center">
              <Text fontSize="md">
                <b>NO</b> (+47) 46 761 761
              </Text>
              <Text fontSize="md">
                <b>@</b> info@firegrader.com
              </Text>
            </Stack>
            <Divider orientation="horizontal" />
          </Stack>
        </Center>
      </Box>

      {/* Form Section */}
      <Box pt="20px" pb="80px" w="80%">
        {messageSent && (
          <Text fontSize="2xl" color="green.500" mb="20px">
            Message sent successfully!
          </Text>
        )}
        <Text fontSize="2xl" color="white" mb="20px">
          Send us an email!
        </Text>
        <form ref={form} onSubmit={sendEmail}>
          <FormControl>
            <Input
              bg="white"
              placeholder="Your Name"
              type="text"
              value={name}
              name="from_name"
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              bg="white"
              placeholder="Your Email (we will never share it)"
              mt="20px"
              value={email}
              name="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            <Textarea
              bg="white"
              placeholder="Your Message"
              h="200px"
              mt="20px"
              value={message}
              name="message"
              onChange={(e) => setMessage(e.target.value)}
            />
            <Button type="submit" size="lg" mt="20px">
              Submit
            </Button>
          </FormControl>
        </form>
      </Box>
    </VStack>
  );
};

export default Contact;
