import React, { useState, useEffect } from "react";
import {
  Box,
  Image,
  Text,
  VStack,
  Flex,
  Grid,
  useBreakpointValue,
  AspectRatio,
  Center,
  IconButton,
} from "@chakra-ui/react";
import { ArrowBackIcon, ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import usePortfolioData from "../hooks/usePortfolioData";

const PortfolioVideoItem = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const isMobile = window.innerWidth <= 768;

  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(null);

  const { itemId } = useParams();
  const { isLoading, portfolio: portfolioItems } = usePortfolioData();

  const textSize = useBreakpointValue({ base: "sm", md: "lg" });
  const videoWidth = useBreakpointValue({ base: "90%", md: "80%" });

  const [showId, setShowId] = useState(false);

  const [typedChars, setTypedChars] = useState("");

  const gridColumns = useBreakpointValue({
    base: "repeat(1, 1fr)",
    sm: "repeat(1, 1fr)",
    md: "repeat(1, 1fr)",
    lg: "repeat(3, 1fr)",
  });

  useEffect(() => {
    const handleKeyPress = (e) => {
      setTypedChars((chars) => {
        const updatedChars = chars + e.key;
        if (updatedChars.includes("showid")) {
          setShowId((prev) => !prev);
          return "";
        }
        return updatedChars;
      });
    };

    window.addEventListener("keypress", handleKeyPress);

    return () => {
      window.removeEventListener("keypress", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (isImageModalOpen) {
        if (e.key === "ArrowRight") {
          handleNext();
        } else if (e.key === "ArrowLeft") {
          handlePrev();
        } else if (e.key === "Escape") {
          setImageModalOpen(false);
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [isImageModalOpen]);

  if (isLoading)
    return (
      <Box color="white" mt="90px" p={{ base: 4, md: 6, lg: 8 }} mb={5}>
        {" "}
        Loading{" "}
      </Box>
    );

  const item = portfolioItems.find((item) => item.id === parseInt(itemId));
  const images = item.otherImages.filter((image) => image !== "");
  const adjustedGridColumns =
    gridColumns === "repeat(3, 1fr)"
      ? `repeat(${Math.min(images.length, 3)}, 1fr)`
      : gridColumns;

  const handleNext = () => {
    setCurrentImageIndex((prev) => (prev + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const itemInfoParts = item.info
    .replace(/\s/g, " ")
    .split(" | ")
    .map((part, i, arr) => {
      const [boldPart, normalPart] = part.split(":").map((str) => str.trim());
      return (
        <Text fontSize={textSize} key={i}>
          <b>{boldPart}: </b>
          {normalPart}
          {i < arr.length - 1 && !isMobile && " | "}
          {/* The logic in the above line ensures that the last item will not have the dividing bar and the bars don't appear on mobile. */}
        </Text>
      );
    });

  return (
    <Box color="white" mt="90px" p={{ base: 4, md: 6, lg: 8 }} mb={5}>
      <Center
        h="auto"
        w="full"
        p={{ base: 2, md: 4, lg: 6 }}
        mt={{ base: 4, md: 4, lg: 0 }}
      >
        <AspectRatio maxW="960px" maxH="540px" width={videoWidth}>
          <iframe title={item.title} src={item.videoUrl} allowFullScreen />
        </AspectRatio>
      </Center>

      <VStack spacing={4} align="center" mt={5}>
        <Text
          fontSize="2xl"
          textAlign="center"
          fontWeight="bold"
          position="relative"
        >
          {showId ? item.id : item.title}
        </Text>
        <Text fontSize="lg" textAlign="center">
          {item.text.split("~p").map((paragraph, index) => (
            <p key={index} style={{ paddingTop: "2px", paddingBottom: "2px" }}>
              {paragraph}
            </p>
          ))}
        </Text>

        <Flex
          wrap="wrap"
          justifyContent="center"
          alignItems="center"
          gridGap={2}
          p={6}
        >
          {itemInfoParts}
        </Flex>
      </VStack>

      <Flex justifyContent="center" mt={5} mb={5}>
        <Grid
          templateColumns={adjustedGridColumns}
          gap={6}
          mx="auto"
          justifyItems="center"
          placeItems="center"
        >
          {images.map((image, index) => (
            <Box
              key={index}
              onClick={() => {
                if (!isMobile) {
                  setCurrentImageIndex(index);
                  setImageModalOpen(true);
                }
              }}
              h="250px"
              w="full"
              position="relative"
            >
              <Image
                src={image}
                alt={`Other Image ${index + 1}`}
                objectFit="cover"
                h="100%"
                w="100%"
                cursor="pointer"
              />
            </Box>
          ))}
        </Grid>
      </Flex>

      {isImageModalOpen && (
        <Box
          position="fixed"
          top="0"
          left="0"
          width="100vw"
          height="100vh"
          bg="rgba(0, 0, 0, 0.8)"
          display="flex"
          justifyContent="center"
          alignItems="center"
          zIndex="1000"
          onClick={() => setImageModalOpen(false)}
        >
          <Box
            position="relative"
            display="flex"
            alignItems="center"
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton
              icon={<ArrowBackIcon />}
              onClick={handlePrev}
              aria-label="Previous Image"
              size="lg"
              variant="ghost"
              color="white"
              position="absolute"
              left="-50px"
            />
            <Image
              src={images[currentImageIndex]}
              alt="Viewed Image"
              maxH="80vh"
              maxW="80vw"
            />
            <IconButton
              icon={<ArrowForwardIcon />}
              onClick={handleNext}
              aria-label="Next Image"
              size="lg"
              variant="ghost"
              color="white"
              position="absolute"
              right="-50px"
            />
            <IconButton
              icon={<CloseIcon />}
              onClick={() => setImageModalOpen(false)}
              aria-label="Close Modal"
              position="absolute"
              top="0"
              right="0"
              size="lg"
              variant="ghost"
              color="white"
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default PortfolioVideoItem;
