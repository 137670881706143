import * as React from "react";
import { Box, Flex } from "@chakra-ui/react";
import { useLocation } from "react-router-dom";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { Analytics } from "@vercel/analytics/react";

const MainLayout = ({ children }) => {
  const location = useLocation();
  const showHeader = location.pathname !== "/";
  const showFooter = location.pathname !== "/";

  return (
    <div>
      <Flex direction="column" minH="100vh" p={0}>
        {showHeader && <Header />}
        <Box bg="gray.700">
          {children}
          <Analytics />
        </Box>
        {showFooter && <Footer />}
      </Flex>
    </div>
  );
};

export default MainLayout;
