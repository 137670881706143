import React from "react";
import { Box, VStack, Center, Stack, Text, Divider } from "@chakra-ui/react";

const StudySportvest = () => {
  return (
    <VStack spacing={10}>

      {/* Case Section */}
      <Box w="100%" pt='120px' pb='20px'>
        <Center>
          <Stack spacing={4} maxWidth="1200px">
            <Box position="relative">
              <Text fontSize="2xl" fontWeight="bold" align="center">Study Sportvest</Text>
            </Box>
            <Divider orientation="horizontal" />
          </Stack>
        </Center>
      </Box>

    </VStack>
  )
};

export default StudySportvest;
