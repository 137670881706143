export const clientLogos = [
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_assmek.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_acobrasil-1.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_aker.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_anacouto-1.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_dpz-1.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_edison.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_ekornes.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_fmc.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_foraform_2.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_havyard.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_im.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_inpower.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_itau-1.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_jwt.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_kib.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_leo.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_mccann.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_niely.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_ogilvy.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_rufo.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_scana.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_starcom.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_talkingnation.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_tussa.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_ulmatec.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_ulstein.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_vanylven.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_vartdal.png",
  "https://fg-clientpreview.s3.eu-north-1.amazonaws.com/MainWebsite/Assets/ClientsLogo/clients_volda.png",
];
