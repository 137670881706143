export const casesItems = [
    {
      title: "Reset Skytebane",
      imageUrl: "http://www.firegrader.com/site/wp-content/uploads/2017/01/vti_Case_08.jpg",
      caseUrl: "/cases/case-reset-skytebane"
    },
    {
      title: 'Sportvest "Junior Multi Arena"',
      imageUrl: "http://www.firegrader.com/site/wp-content/uploads/2015/01/case_sportvest_08_small.jpg",
      caseUrl: "/cases/case-study-sportvest"
    },
  ]