import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Center,
  Stack,
  Text,
  Divider,
  SimpleGrid,
  Button,
  ButtonGroup,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tooltip,
  Fade,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { useColorModeValue } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import usePortfolioData from "../hooks/usePortfolioData";

const Portfolio = () => {
  const {
    isLoading: isLoadingItems,
    portfolio: portfolioItems,
  } = usePortfolioData();

  const { isLoading: isLoadingCategories, data: categories } = useQuery({
    queryKey: ["portfolioDataCategories"],
    queryFn: () =>
      axios
        .get(`${process.env.REACT_APP_BACKEND_FIREGRADER}/api/categories`)
        .then((res) => ["All", ...res.data]),
  });

  const navigate = useNavigate();

  const handleItemClick = (item) => {
    const pageType = item.videoUrl ? "video" : "image";
    localStorage.setItem("scrollPosition", window.scrollY);
    localStorage.setItem("portfolioPage", page);
    navigate(`/portfolio/${pageType}/${item.id}`);
  };

  const [selectedCategory, setSelectedCategory] = useState("All");

  const filteredItems =
    selectedCategory === "All"
      ? portfolioItems
      : portfolioItems.filter((item) =>
          item.categories.includes(selectedCategory)
        );
  const itemsPerPage = 18;
  const [page, setPage] = useState(
    parseInt(localStorage.getItem("portfolioPage")) || 1
  );
  const columns = useBreakpointValue({ base: 1, md: 2, lg: 3 });
  const isMobile = useBreakpointValue({ base: true, sm: false });
  const loadMore = () => {
    setPage(page + 1);
  };

  const bg = useColorModeValue("white", "white");
  const color = useColorModeValue("gray.800", "gray.800");

  useEffect(() => {
    const scrollPosition = parseInt(localStorage.getItem("scrollPosition"));
    if (scrollPosition) {
      window.scrollTo(0, scrollPosition);
    }
  }, []);

  return (
    <VStack spacing={10} minHeight="100vh">
      {/* Portfolio Section */}
      <Box
        w="100%"
        pt={{ base: "160px", md: "160px" }}
        pb={{ base: "10px", md: "20px" }}
        color="white"
      >
        <Center>
          <Stack spacing={4} maxWidth="1200px">
            <Box position="relative">
              <Text
                fontSize={{ base: "xl", md: "2xl" }}
                fontWeight="bold"
                align="center"
              >
                Portfolio
              </Text>
            </Box>
            <Divider orientation="horizontal" />
          </Stack>
        </Center>
      </Box>

      {/* Portfolio Grid Section */}
      <Box
        pb={{ base: "40px", md: "80px" }}
        px={{ base: "4", md: "0" }}
        color="white"
      >
        <Center>
          {isMobile ? (
            <Menu placement="bottom">
              <MenuButton as={Button} mb={4}>
                {selectedCategory === "All"
                  ? "Select category"
                  : selectedCategory}
              </MenuButton>
              <MenuList bg={bg} color={color}>
                {isLoadingCategories
                  ? null
                  : categories?.map((category, index) => (
                      <MenuItem
                        key={index}
                        onClick={() => setSelectedCategory(category)}
                        bg={category === selectedCategory ? "gray.600" : ""}
                        color={category === selectedCategory ? "white" : ""}
                      >
                        {category}
                      </MenuItem>
                    ))}
              </MenuList>
            </Menu>
          ) : (
            <ButtonGroup mb={4}>
              {isLoadingCategories ? (
                <></>
              ) : (
                categories?.map((category, index) => (
                  <Button
                    key={index}
                    onClick={() => setSelectedCategory(category)}
                    bg={category === selectedCategory ? "gray.800" : "gray.200"}
                    color={category === selectedCategory ? "white" : "black"}
                  >
                    {category}
                  </Button>
                ))
              )}
            </ButtonGroup>
          )}
        </Center>
        <SimpleGrid columns={columns} spacing={10} p={{ base: "0", md: "4" }}>
          {isLoadingItems ? (
            <></>
          ) : (
            Array.isArray(filteredItems) &&
            filteredItems.slice(0, page * itemsPerPage).map((item, index) => (
              <Box
                key={index}
                width={{ base: "100%", md: "400px" }}
                height={"300px"}
                position="relative"
                borderRadius="lg"
                cursor="pointer"
                onClick={() => handleItemClick(item)}
                mb={{ base: "6", md: "0" }}
              >
                <img
                  src={item.thumb ? item.thumb : item.imageUrl}
                  style={{
                    objectFit: "cover",
                    objectPosition: "center",
                    width: "100%",
                    height: "100%",
                  }}
                  alt={item.title}
                />
                {!isMobile && (
                  <Box
                    position="absolute"
                    top={0}
                    left={0}
                    right={0}
                    bottom={0}
                    bg="rgba(0,0,0,0.5)"
                    opacity={0}
                    transition="opacity .3s ease-in-out"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    _hover={{ opacity: 1 }}
                  >
                    <Text
                      color="white"
                      fontWeight="bold"
                      fontSize="lg"
                      textAlign="center"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                      maxWidth="90%"
                    >
                      {item.title}
                    </Text>
                  </Box>
                )}
                {isMobile && (
                  <Fade in={isMobile}>
                    <Tooltip
                      label={item.title}
                      aria-label={item.title}
                      placement="bottom"
                    >
                      <Flex p="1" justify="center">
                        <Text
                          mt="1"
                          fontWeight="semibold"
                          lineHeight="tight"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                          maxWidth="90%"
                        >
                          {item.title}
                        </Text>
                      </Flex>
                    </Tooltip>
                  </Fade>
                )}
              </Box>
            ))
          )}
        </SimpleGrid>
        {page * itemsPerPage < filteredItems?.length && (
          <Center mt={6}>
            <Button onClick={loadMore}>Load More</Button>
          </Center>
        )}
      </Box>
    </VStack>
  );
};

export default Portfolio;
