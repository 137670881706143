import { useContext } from "react";
import { PortfolioItemContext } from "../routes";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const usePortfolioData = () => {
  const { portfolio, setPortfolio } = useContext(PortfolioItemContext);

  const { isLoading } = useQuery(
    ["portfolioDataItems"],
    async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_BACKEND_FIREGRADER}/api/portfolioItems`
      );
      setPortfolio(res.data);
      //reverse at the end so the last db entries appear on top
      return res.data.reverse();
    },
    { enabled: !!portfolio }
  );

  return { isLoading, portfolio };
};

export default usePortfolioData;
