import React from "react";
import { Box, VStack, Center, Stack, Text, Divider, SimpleGrid, Image, useBreakpointValue } from "@chakra-ui/react";
import { clientLogos } from "../lists/AboutLogos"

const About = () => {

  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <VStack spacing={10}>

      {/* Studio Section */}
      <Box w="100%" pt='160px' pb='80px' color="white">
        <Center>
          <Stack spacing={4} maxWidth="1200px" px={isMobile ? '4' : '0'}>
            <Box position="relative">
              <Text fontSize="2xl" fontWeight="bold" align="center">Studio</Text>
              <Divider orientation="horizontal" />
            </Box>
            <Text fontSize="md" align="center">
              “Fire grader” means four degrees (celsius) in norwegian. This is when the water is at its most heavy and dense state, before it expands. This is our mission: to give weight to and expand your ideas, by visualizing them.
            </Text>
            <Text fontSize="md" align="center">
              We have worked on countless productions, ranging from local business to multinational corporations like Coca-Cola, Ulstein Group, Sœtre, Ekornes, McCann Erickson, Leo Burnett, Starcom, Aker and Globosat, just to name a few.
            </Text>
            <Text fontSize="md" align="center">
              In addition to the core team, we have several collaborators, from concept artists, designers and architects, to filmmakers, photographers and composers that we work with on projects that require more than traditional CGI. With us, you can rest assured that you will always be in good hands
            </Text>
          </Stack>
        </Center>
      </Box>

      {/* What We Do Section */}
      <Box w="100%" pt='20px' pb='80px' color="white">
        <Center>
          <Stack spacing={4} maxWidth="1200px" px={isMobile ? '4' : '0'}>
            <Box position="relative">
              <Text fontSize="2xl" fontWeight="bold" align="center">What We Do</Text>
              <Divider orientation="horizontal" />
            </Box>

            <Text fontSize="md" align="center">
              We create visual experiences.
            </Text>
            <Text fontSize="md" align="center">
              Whether through an illustration or an animation, our mission is to transform your idea into a unique visual experience. During the process, we devote special attention to the flow and exchange of ideas between you and us, providing a collaborative and versatile creative environment, adding value to the production and maximizing the results.
            </Text>
            <Text fontSize="md" align="center">
              We work in a wide range of industries, genres and styles. Imagination is the only border – let’s overcome it together.
            </Text>
            <Text fontSize="md" align="center">
              In a nutshell our services are:
            </Text>
            <Text fontSize="lg" fontWeight="bold" align="center">
              2D Illustration  |  3D illustration  |  Motion and animation  |  Visual effects
            </Text>
            <Text fontSize="md" align="center">
              Need our services, or have any questions? Please do not hesitate to{' '}
              <a href="/contact">
                <u>contact us!</u>
              </a>
            </Text>
          </Stack>
        </Center>
      </Box>

      {/* Clients Section */}
      <Box w="100%" pt='20px' pb='80px' color="white">
        <Center>
          <Stack spacing={4} maxWidth="1200px">
            <Box position="relative">
              <Text fontSize="2xl" fontWeight="bold" align="center">Clients</Text>
              <Divider orientation="horizontal" />
            </Box>
            <SimpleGrid columns={{ base: 2, md: 4 }} spacing="20px">
              {clientLogos.map((logoUrl, index) => (
                <Image
                  key={index}
                  src={logoUrl}
                  alt={`Client logo ${index + 1}`}
                  objectFit="contain"
                />
              ))}
            </SimpleGrid>
          </Stack>
        </Center>
      </Box>
      
    </VStack>
  );
}

export default About;
