import React, { createContext, useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import About from '../pages/About';
import Portfolio from '../pages/Portfolio';
import Cases from '../pages/Cases';
import Contact from '../pages/Contact';

import PortfolioImageItem from '../components/PortfolioImageItem';
import PortfolioVideoItem from '../components/PortfolioVideoItem';

import ResetSkytebane from '../pages/cases/ResetSkytebane';
import StudySportvest from '../pages/cases/StudySportvest';

import MainLayout from '../layouts/MainLayout';

import {
  QueryClient,
  QueryClientProvider
} from '@tanstack/react-query'

const queryClient = new QueryClient()

const PortfolioItemContext = createContext(null);

const MyRoutes = () => {

  const [portfolio, setPortfolio] = useState({});

  return (
    <QueryClientProvider client={queryClient}>
      <PortfolioItemContext.Provider value={{portfolio, setPortfolio}}>
        <Router>
          <MainLayout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/portfolio" element={<Portfolio />} />
              <Route path="/portfolio/image/:itemId" element={<PortfolioImageItem />} />
              <Route path="/portfolio/video/:itemId" element={<PortfolioVideoItem />} />
              <Route path="/cases" element={<Cases />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/cases/case-reset-skytebane" element={<ResetSkytebane />} />
              <Route path="/cases/case-study-sportvest" element={<StudySportvest />} />
            </Routes>
          </MainLayout>
        </Router>
      </PortfolioItemContext.Provider>
    </QueryClientProvider>
  );
};

export default MyRoutes;

export {PortfolioItemContext};