import React, { useState, useEffect, useRef } from 'react';
import { Box } from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';

const MotionBox = motion(Box);

const Carousel = ({ images }) => {
  const [index, setIndex] = useState(0);
  const previousIndex = useRef(index);

  useEffect(() => {
    const interval = setInterval(() => {
      previousIndex.current = index;
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);
    return () => clearInterval(interval);
  }, [images, index]);

  return (
    <Box position="relative" width="100vw" height="100vh">
      <AnimatePresence>
        {index !== previousIndex.current && (
          <MotionBox
            key={images[previousIndex.current]}
            width="100vw"
            height="100vh"
            position="absolute"
            bgImage={`url(${images[previousIndex.current]})`}
            bgPosition="center"
            bgSize="cover"
            bgRepeat="no-repeat"
            sx={{
              '&::after': {
                content: '""',
                position: 'absolute',
                top: 0,
                right: 0,
                bottom: 0,
                left: 0,
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
              },
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 4 }}
          />
        )}
        <MotionBox
          key={images[index]}
          width="100vw"
          height="100vh"
          position="absolute"
          bgImage={`url(${images[index]})`}
          bgPosition="center"
          bgSize="cover"
          bgRepeat="no-repeat"
          sx={{
            '&::after': {
              content: '""',
              position: 'absolute',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1 }}
        />
      </AnimatePresence>
    </Box>
  );
};

export default Carousel;
