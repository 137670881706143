import React, { useState, useEffect } from "react";
import {
  Box,
  VStack,
  Heading,
  UnorderedList,
  ListItem,
  Link,
  Text,
  Stack,
  useBreakpointValue,
  IconButton,
} from "@chakra-ui/react";
import { FaArrowUp } from "react-icons/fa";

const Footer = () => {
  const stackDirection = useBreakpointValue({ base: "column", md: "row" });
  const stackAlign = useBreakpointValue({ base: "center", md: "stretch" });

  const [showGoToTop, setShowGoToTop] = useState(false);

  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setShowGoToTop(scrollTop > 0);
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleGoToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <Box
      as="footer"
      w="100%"
      p={6}
      backgroundColor="gray.800"
      color="white"
      mt="auto"
    >
      <VStack spacing={6}>
        <Stack
          direction={stackDirection}
          spacing={10}
          justify="space-between"
          align={stackAlign}
        >
          <FooterSection
            title="Firegrader"
            links={[
              {
                text: "The Studio",
                href: "http://www.firegrader.com/site/studio/",
              },
              {
                text: "Portfolio",
                href: "http://www.firegrader.com/site/portfolio/",
              },
              {
                text: "Contact",
                href: "http://www.firegrader.com/site/contact/",
              },
              {
                text: "Client Studio",
                href: "https://clientstudio.firegrader.com/",
              },
            ]}
          />
          <FooterSection
            title="Our Services"
            items={[
              "2D Illustration",
              "3D Illustration",
              "Motion",
              "Animation",
              "Visual Effects",
            ]}
          />
          <FooterSection
            title="Contact Norway"
            items={[
              "Luhrtoppen 2, Lørenskog",
              "(+47) 46 761 761",
              "info@firegrader.com",
            ]}
            // additionalTitle="Brasil"
            // additionalItems={["(+xx xx) xxxx xxxx"]}
          />
          <FooterSection
            title="Social"
            links={[
              {
                text: "Facebook",
                href: "https://www.facebook.com/firegrader",
                isExternal: true,
              },
              {
                text: "Twitter",
                href: "https://twitter.com/firegrader",
                isExternal: true,
              },
              {
                text: "Vimeo",
                href: "https://vimeo.com/firegrader",
                isExternal: true,
              },
            ]}
          />
        </Stack>
        <Text textAlign="center" fontSize="sm">
          Copyright Firegrader {currentYear}. All Rights Reserved
        </Text>
      </VStack>

      <IconButton
        icon={<FaArrowUp />}
        bgColor="gray.600"
        size="md"
        variant="ghost"
        colorScheme="whiteAlpha"
        aria-label="Go to top"
        position="fixed"
        bottom={4}
        right={4}
        opacity={showGoToTop ? 1 : 0}
        onClick={handleGoToTop}
        transition="opacity 0.3s"
        _hover={{ opacity: 1 }}
      />
    </Box>
  );
};

const FooterSection = ({
  title,
  items,
  links,
  additionalTitle,
  additionalItems,
}) => {
  const alignment = useBreakpointValue({ base: "center", md: "start" });

  return (
    <VStack align={alignment} spacing={4}>
      <Heading as="h3" size="sm">
        {title}
      </Heading>
      <UnorderedList styleType="none" spacing={1} margin={0}>
        {items?.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
        {links?.map((link, index) => (
          <ListItem key={index}>
            <Link href={link.href} isExternal={link.isExternal}>
              {link.text}
            </Link>
          </ListItem>
        ))}
      </UnorderedList>
      {additionalTitle && (
        <Heading as="h3" size="sm">
          {additionalTitle}
        </Heading>
      )}
      <UnorderedList styleType="none" spacing={1} margin={0}>
        {additionalItems?.map((item, index) => (
          <ListItem key={index}>{item}</ListItem>
        ))}
      </UnorderedList>
    </VStack>
  );
};

export default Footer;
