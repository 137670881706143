import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Text,
  Button,
  VStack,
  Center,
  ButtonGroup,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import Carousel from "../components/Carousel";
import usePortfolioData from "../hooks/usePortfolioData";

const textVariants = {
  hidden: { y: "-100vh", opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 120, damping: 20 },
  },
};

const MotionText = motion(Text);

const HomePage = () => {
  const navigate = useNavigate();

  const handleOurWorkClick = () => {
    navigate("/portfolio");
  };

  const handleClientStudioClick = () => {
    window.location.href = "https://clientstudio.firegrader.com/";
  };

  const { portfolio, isLoading } = usePortfolioData();

  const carouselImages =
    portfolio.length > 0
      ? portfolio.slice(0, 5).map((item) => item.imageUrl)
      : [];

  return (
    <Box pos="relative" w="100%" h="100%">
      <Carousel images={carouselImages} />
      <Center pos="absolute" top="0" left="0" w="100%" h="100%">
        <VStack spacing={[2, 3, 4]}>
          <MotionText
            fontSize={["60px", "100px", "100px"]}
            fontWeight="bold"
            color="white"
            textShadow="2px 2px 3px rgba(0,0,0,1)"
            initial="hidden"
            animate="visible"
            variants={textVariants}
          >
            FireGrader
          </MotionText>
          <ButtonGroup variant="outline" spacing={[3, 4, 6]}>
            <Button
              onClick={handleOurWorkClick}
              fontWeight="bold"
              colorScheme="blackAlpha"
              bg="gray.800"
              variant="solid"
              color="white"
              borderRadius="none"
              fontSize={["md", "lg", "xl"]}
              p={["2", "3", "4"]}
            >
              Our Work
            </Button>
            <Button
              onClick={handleClientStudioClick}
              fontWeight="bold"
              colorScheme="blackAlpha"
              bg="gray.800"
              variant="solid"
              color="white"
              borderRadius="none"
              fontSize={["md", "lg", "xl"]}
              p={["2", "3", "4"]}
            >
              Client Studio
            </Button>
          </ButtonGroup>
        </VStack>
      </Center>
    </Box>
  );
};

export default HomePage;
